import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/style.css";
import { Row, Col, Container, Table } from "react-bootstrap";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import "react-toastify/dist/ReactToastify.css";
import { saveAs } from "file-saver";
import axios from "axios";
import { FaSync } from "react-icons/fa";
import AWS from "aws-sdk";
import { SERVERURL } from "../configuration/config_url";
import { FaCloudDownloadAlt } from "react-icons/fa";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { toast } from "react-toastify";
import { NavLink } from "react-router-dom";

let data = [];

AWS.config.update({
  accessKeyId: "AKIAXTPFNQTGXF3CI35R",
  secretAccessKey: "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",
});
const S3_BUCKET = "solpub-list-data";
const REGION = "us-east-1";
const URL_EXPIRATION_TIME = 900; // in seconds
const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});

function Dashboard(props) {
  const [dataObject, setDataObject] = useState([]);
  const [page, setPage] = useState(1);
  const [status, setStatus] = useState();
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [noOfPages, setNoOfPages] = useState([]);
  const [submittedDate, setSubmittedDate] = useState();
  const [andDate, setAndDate] = useState();
  const [items, setItems] = useState([]);
  const username = "admin";
  const password = "f3c6dfbe50cff2d05dce3180e3569910";
  const token = Buffer.from(`${username}:${password}`, "utf8").toString(
    "base64"
  );

  useEffect(() => {
    axios
      .get(SERVERURL + "loadTitrationStep", {
        headers: {
          // Overwrite Axios's automatically set Content-Type
          "Content-Type": "application/json",
          Authorization: `Basic ${token}`,
        },
      })
      .then((res) => {
        if (res && res.data) {
          console.log(res.data);
          data = res.data;
          const itemsPerPage = 5;

          setNoOfPages(Math.ceil(data.length / itemsPerPage));
          setDataObject(data);
        }
      })
      .catch((error) => {
        toast.error(
          "Something went wrong server is not responding",
          { position: toast.POSITION.TOP_CENTER },
          { autoClose: 3000 }
        );
        console.error("Something went wrong!", error);
      });
  }, []);
  console.log(submittedDate, "Date");
  console.log(andDate, "and Date");
  const handleChange = (event, value) => {
    setPage(value);
  };
  const submitdate = (e) => {
    setSubmittedDate(e.target.value);
  };
  const Danddate = (e) => {
    setAndDate(e.target.value);
  };
  const dateFunction = async () => {
    console.log();
    const removeObj = JSON.stringify({
      startDate: submittedDate,
      endDate: andDate,
    });

    const res = await axios.post(
      // "https://listool.digitivy.com/list-tool-runner/rest/titration/loadTitrationStepUsingDate",
      SERVERURL + "loadTitrationStepUsingDate",
      removeObj,
      {
        headers: {
          // Overwrite Axios's automatically set Content-Type
          "Content-Type": "application/json",
          Authorization: `Basic ${token}`,
        },
      }
    );
    console.log(res.data, "Data from Response");
    if (res.data !== "") {
      setDataObject(res.data);
    } else {
      notifyNoData();
    }
  };

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("userDetails"));
    if (items) {
      setItems(items);
    }
  }, []);

  // console.log(items, "items in dashboard");

  const notifyNoData = () => {
    toast.error(
      "No data available in the Selected Dates",
      { position: toast.POSITION.TOP_CENTER },
      { autoClose: 5000 }
    );
  };

  const generatePresignedUrlDownloadFile = (item) => {
    myBucket.getSignedUrl(
      "getObject",
      {
        Key: item,
        Expires: URL_EXPIRATION_TIME,
      },
      (err, url) => {
        saveAs(url);
        return url;
      }
    );
  };

  const statusFunction = async (e) => {
    const data = JSON.stringify({ status: e });

    const res = await axios.post(
      // "https://listool.digitivy.com/list-tool-runner/rest/titration/loadTitrationStepUsingStatus",
      SERVERURL + "loadTitrationStepUsingStatus",
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${token}`,
        },
      }
    );
    setDataObject(res.data);
  };

  return (
    <Row>
      <Col xs={2} md={2} style={{ paddingRight: 0, paddingLeft: 0 }}>
        <Sidebar />
      </Col>
      <Col
        xs={10}
        md={10}
        style={{ paddingLeft: 0, backgroundColor: "#F9FBFD" }}
      >
        <Navbar />
        <h3 style={{ paddingLeft: 30, color: "#1D417A" }}>Titrations</h3>
        {!items.email || !items.profile ? (
          <div>
            <p> No Login Credentials Please login from Arcman Website</p>
          </div>
        ) : (
          <Container>
            <Col sm={8} md={8} lg={12}>
              <div className="boxform1">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    padding: "20 10",
                    justifyContent: "space-between",
                  }}
                >
                  <select
                    onChange={(e) => {
                      setStatus(e.target.value);
                      statusFunction(e.target.value);
                    }}
                  >
                    <option value="">Select Status...</option>
                    <option value="completed">Complete</option>
                    <option value="In progress">In Progress</option>
                  </select>
                  <div>
                    <label htmlFor="date" style={{ marginRight: 10 }}>
                      Submitted Date From
                    </label>
                    <input
                      type="date"
                      onChange={submitdate}
                      value={submittedDate}
                    />
                  </div>

                  <div>
                    <label htmlFor="date" style={{ marginRight: 10 }}>
                      Submitted Date to
                    </label>
                    <input type="date" onChange={Danddate} value={andDate} />
                  </div>
                  <div>
                    <FaSync onClick={dateFunction} />
                  </div>
                </div>
              </div>
            </Col>
            <Col sm={12} md={12} lg={12}>
              <div className="Dashboardheading">
                <p>Showing Total {data.length} Entries</p>
              </div>
              <div style={{ marginBottom: 10 }}>
                <Stack spacing={6}>
                  <Pagination
                    count={noOfPages}
                    page={page}
                    onChange={handleChange}
                    defaultPage={1}
                    size="small"
                    color="primary"
                  />
                </Stack>
              </div>

              <Table striped bordered hover>
                <thead>
                  <tr className="Table-headers">
                    <th>Titration Name</th>
                    <th>Submitted Date</th>
                    <th>Input File</th>
                    <th>Match File</th>
                    <th>Action</th>
                    <th>Total Records</th>
                    <th>Match Count</th>
                    <th>Suppresion Count</th>
                    <th>Output File</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {dataObject &&
                    dataObject.length > 0 &&
                    dataObject
                      .slice((page - 1) * itemsPerPage, page * itemsPerPage)
                      .map((obj, index) => (
                        <tr key={index}>
                          <td>{obj.titrationName}</td>
                          {/* <NavLink
                          to={{
                            pathname: "/addstep",
                            state: {
                              data: obj.titrationName,
                              id: obj.id,
                              sourceFile: obj && obj.steps[0].sourceFile,
                              stepNum:
                                obj &&
                                obj.steps &&
                                obj.steps[0] &&
                                obj.steps[0].step_num,
                            },
                          }}
                        >
                          <td>{obj.titrationName}</td>
                        </NavLink> */}
                          {obj &&
                            obj.steps &&
                            obj.steps.map((Name, index) => (
                              <td key={index}>{Name.submittedDateString}</td>
                            ))}
                          {obj &&
                            obj.steps &&
                            obj.steps.map((Name, index) => (
                              <td key={index}>
                                <FaCloudDownloadAlt
                                  style={{
                                    color: "green",
                                    height: 25,
                                    width: 25,
                                  }}
                                  onClick={() =>
                                    generatePresignedUrlDownloadFile(
                                      Name.sourceFile
                                    )
                                  }
                                />
                              </td>
                            ))}
                          {obj &&
                            obj.steps &&
                            obj.steps.map((Name, index) => (
                              <td key={index}>
                                <FaCloudDownloadAlt
                                  style={{
                                    color: "green",
                                    height: 25,
                                    width: 25,
                                  }}
                                  onClick={() =>
                                    generatePresignedUrlDownloadFile(
                                      Name.matchFile
                                    )
                                  }
                                />
                              </td>
                            ))}
                          {obj &&
                            obj.steps &&
                            obj.steps.map((Name, index) => (
                              <td key={index}>{Name.action}</td>
                            ))}
                          {obj &&
                            obj.steps &&
                            obj.steps.map((Name, index) => (
                              <td key={index}>{Name.totalCount}</td>
                            ))}
                          {obj &&
                            obj.steps &&
                            obj.steps.map((Name, index) => (
                              <td key={index}>{Name.matchfile_count}</td>
                            ))}
                          {obj.steps &&
                            obj.steps.length > 0 &&
                            obj.steps.map((Name, index) => (
                              <td key={index}>{Name.matchCount}</td>
                            ))}
                          {obj &&
                            obj.steps &&
                            obj.steps.map((Name, index) => (
                              <td key={index}>{Name.suppressCount}</td>
                            ))}
                          {obj &&
                            obj.steps &&
                            obj.steps.map((Name, index) => (
                              <td key={index}>
                                <FaCloudDownloadAlt
                                  style={{
                                    color: "green",
                                    height: 25,
                                    width: 25,
                                  }}
                                  onClick={() =>
                                    generatePresignedUrlDownloadFile(
                                      Name.outputFilePath
                                    )
                                  }
                                />
                              </td>
                            ))}
                          {obj &&
                            obj.steps &&
                            obj.steps.map((Name, index) => (
                              <td key={index}>{Name.status}</td>
                            ))}
                        </tr>
                      ))}
                </tbody>
              </Table>
            </Col>
          </Container>
        )}
      </Col>
    </Row>
  );
}

export default Dashboard;
