import React, { useState, useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { useIdleTimer } from "react-idle-timer";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";

let userDetails = {};
function Titlepage() {
  const search = useLocation().search;
  let steps_id = new URLSearchParams(search).get("steps_id");
  let campaign_id = new URLSearchParams(search).get("campaign_id");
  let profile = new URLSearchParams(search).get("profile");
  let email = new URLSearchParams(search).get("email");
  let user_id = new URLSearchParams(search).get("user_id");
  //   const [items, setItems] = useState([]);

  userDetails = {
    steps_id: steps_id,
    campaign_id: campaign_id,
    profile: profile,
    email: email,
    user_id: user_id,
  };

  localStorage.setItem("userDetails", JSON.stringify(userDetails));

  // const handleOnIdle = (event) => {
  //   console.log("user is idle", event);
  //   console.log("last active", getLastActiveTime());
  //   localStorage.clear();
  //   console.log("Local Storage Cleared");
  // };

  // const handleOnActive = (event) => {
  //   // console.log("user is active", event);
  //   console.log("time remaining", getRemainingTime());
  // };

  // const handleOnAction = (event) => {
  //   // console.log("user did something", event);
  // };

  // const { getRemainingTime, getLastActiveTime } = useIdleTimer({
  //   timeout: 1000 * 60 * 2,
  //   onIdle: handleOnIdle,
  //   onActive: handleOnActive,
  //   onAction: handleOnAction,
  //   debounce: 500,
  // });
  // //   console.log(timeout);
  // setTimeout(localStorage.clear());

  return (
    <>
      <Row style={{ width: "100%", margin: 0 }}>
        <Col xs={2} md={2} style={{ paddingRight: 0, paddingLeft: 0 }}>
          <Sidebar />
        </Col>

        <Col
          xs={10}
          md={10}
          style={{ paddingLeft: 0, backgroundColor: "#F9FBFD" }}
        >
          <Navbar />
          <h3 style={{ paddingLeft: 30, color: "#1D417A" }}>Welcome User</h3>
          {/* {!items.Email || !items.Profile ? (
          <div>
            <p> No Login Credentials Please login from Arcman Website</p>
          </div>
        ) : ( */}
          <Container>
            <Col sm={8} md={8} lg={12}>
              <div style={{ textAlign: "right", padding: "20 10" }}></div>
            </Col>
            <Col sm={12} md={12} lg={12}>
              {/* <div>
              <Link
                to={{
                  pathname: "https://arcmanuat.solpub.com/",
                }}
                target="_blank"
              >
                Click Here
              </Link>
            </div> */}
              <div className="ntbox">
                <h3
                  style={{
                    color: "#1D417A",
                    marginBottom: 20,
                  }}
                >
                  Listtool
                </h3>
              </div>
            </Col>
          </Container>
          {/* )} */}
        </Col>
      </Row>
    </>
  );
}

export default Titlepage;
