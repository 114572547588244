import React, { useState, useEffect } from "react";
import "../styles/sidebar.css";
import logo from "../images/logo.png";
import { FaTools } from "react-icons/fa";
import dashboard from "../images/dashboard.png";
import { FaUserPlus } from "react-icons/fa";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  NavLink,
  Link,
} from "react-router-dom";

const Sidebar = (props) => {
  // console.log(props, "props in sidebar");
  const [items, setItems] = useState([]);
  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("userDetails"));
    if (items) {
      setItems(items);
    }
  }, []);

  return (
    <>
      <div className="sidenav">
        <div className="d-flex logo">
          <img className="mx-auto" src={logo} alt="logo" />
        </div>

        {!items.email || !items.profile ? (
          <p></p>
        ) : (
          <div>
            <NavLink to="/titrations">
              <FaTools style={{ marginRight: 25 }} />
              Titrations
            </NavLink>
            <NavLink to="/list" style={{ marginTop: 25, marginLeft: 10 }}>
              <img
                src={dashboard}
                alt="dashboard"
                style={{
                  marginRight: 32,
                  width: 15,
                  height: 14,
                }}
              />
              Dashboard
            </NavLink>
            <Link
              // style={{ marginTop: 25, marginLeft: 10 }}
              style={{ color: "white", marginTop: 25, marginLeft: 10 }}
              onClick={() => {
                localStorage.clear();
              }}
            >
              <FaUserPlus style={{ marginRight: 20 }} />
              Logout
            </Link>
          </div>
        )}
      </div>
    </>
  );
};

export default Sidebar;
