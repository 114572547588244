import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/style.css";
import NewTitrationmodal from "./NewTitrationmodal";
import { Row, Col, Container, Modal, Table } from "react-bootstrap";
import Api from "../middleware";
import Sidebar from "./Sidebar";
import AWS from "aws-sdk";
import Navbar from "./Navbar";
import { Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { saveAs } from "file-saver";
import { SERVERURL } from "../configuration/config_url";
import { uploadFile } from "react-s3";
import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { FaCloudDownloadAlt } from "react-icons/fa";
import axios from "axios";
import { FaSync } from "react-icons/fa";
import { FaDownload } from "react-icons/fa";
import { Filter } from "@material-ui/icons";

toast.configure();

let path = "";
// let step = [];
let stepsID = "";
let campaignID = "";
let titname = "";
let multiPath = "";
let filepath = "";
let titrationname = "";
let sourcefile = "";
let filename = "";
let cleandata = [];
let titrationName = "";
let name = "";
let id = "";
// let id2 = "";
let stepConcat = "";
let sendid = "";
let sendname = "";
let dataObj = {};
let stepId = "";
let idEdit = "";

AWS.config.update({
  accessKeyId: "AKIAXTPFNQTGXF3CI35R",
  secretAccessKey: "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",
});

const S3_BUCKET = "solpub-list-data";
const REGION = "us-east-1";
const URL_EXPIRATION_TIME = 900; // in seconds
const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});

function Steps(props) {
  const location = useLocation();
  const [redirectpath, setredirectPath] = useState("");
  const [results, setResults] = useState([]);
  const [titdetails, settitDetails] = useState();
  const [results1, setResults1] = useState([]);
  const [text, setText] = useState();
  const [edit, setEdit] = useState(false);
  const [matchData, setMatchData] = useState(null);
  const [titrationName, setTitrationName] = useState("");
  const [name, setName] = useState("");
  const [multiStep, setMultiStep] = useState([]);
  // const [sfile, setSfile] = useState("");
  const [displayed, setDisplayed] = useState([]);
  const [queueMessage, setQueueMessage] = useState([]);
  const [numberId, setNumberId] = useState();
  // const [titrationid, settitrationId] = useState();
  const [titIdname, setTitIdname] = useState();
  const [stepnum, setstepNum] = useState();
  const [Stepnumber, setStepNumber] = useState();
  const [Step_id, setStep_Id] = useState();
  const [titId, setTitId] = useState();
  const [Totalcount, setTotalCount] = useState();
  const [Matchcount, setMatchCount] = useState();
  const [outputFile, setOutputFile] = useState();
  const [status, setStatus] = useState();
  const [Suppresscount, setSuppressCount] = useState();
  const [Stepname, setStepName] = useState();
  const [checked, setChecked] = useState(true);
  const [sourceCol, setSourceCol] = useState();
  const [matchCol, setMatchCol] = useState();
  const [matchFile, setMatchFile] = useState();
  const [matchType, setMatchType] = useState();
  const [action, setAction] = useState();
  const [confidence, setConfidence] = useState();
  const [source, setSource] = useState();
  // const [sourcefile, setSourcefile] = useState();
  const [editid, setEditid] = useState();
  const username = "admin";
  const password = "f3c6dfbe50cff2d05dce3180e3569910";
  const token = Buffer.from(`${username}:${password}`, "utf8").toString(
    "base64"
  );

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const S3_BUCKET = "list-tool-data";
  const REGION = "us-east-2";
  const ACCESS_KEY = "AKIAQ4QZYNARLW4DVLNW";
  const SECRET_ACCESS_KEY = "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";

  useEffect(() => {
    // setTitrationName(
    //   location &&
    //     location.state &&
    //     location.state.detail &&
    //     location.state.detail.res &&
    //     location.state.detail.res.data &&
    //     location.state.detail.res.data.titrationName
    // );
    setTitrationName(
      location &&
        location.state &&
        location.state.detail &&
        location.state.detail.titrationname
    );
  }, [location]);

  console.log(location, "location in steps page");

  const closeComponent = () => {
    setredirectPath("/");
  };

  // useEffect(() => {
  const loadFunction = () => {
    const data = JSON.stringify({
      titId: id,
      // titId: titrationid,
    });
    axios
      .post(
        // "https://listool.digitivy.com/list-tool-runner/rest/titration/loadSingleTitrationStep",
        SERVERURL + "loadSingleTitrationStep",
        data,
        {
          headers: {
            // Overwrite Axios's automatically set Content-Type
            "Content-Type": "application/json",
            Authorization: `Basic ${token}`,
          },
        }
      )
      .then((res) => setResults(res.data))
      .catch((error) => {
        toast.error(
          "Something went wrong server is not responding",
          { position: toast.POSITION.TOP_CENTER },
          { autoClose: 3000 }
        );
        console.error("Something went wrong!", error);
      });
  };

  const handleSampleClose = () => {
    setShow(false);
    loadFunction();
    // window.location.reload();
  };

  useEffect(() => {
    loadFunction();
  }, []);

  const handleStepNumber = (stepnumber) => {
    // console.log(stepnumber);
    setStepNumber(stepnumber);
  };

  const [showModal, setShow] = useState(false);
  const handleShow = (props) => {
    handleStepname();
    setShow(true);
    // setName(
    //   titrationName
    //     ? titrationName
    //     : location.state.detail.titrationName
    //     ? location.state.detail.titrationName
    //     : null
    // );
  };
  id = location.state.tidId.id;
  // id2 = location.state.id;

  // settitrationId(
  //   (location &&
  //     location.state &&
  //     location.state.tidId &&
  //     location.state.tidId.id) ||
  //     (location && location.state && location.state.id)
  // );
  // titrationname = location.state.tidId.titrationName;

  sourcefile = location.state.detail.sourceFile;

  // setSourcefile(
  //   (location &&
  //     location.state &&
  //     location.state.detail &&
  //     location.state.detail.sourceFile) ||
  //     (location && location.state && location.state.sourceFile)
  // );

  // console.log(sourcefile, "SourceFile");

  // console.log(id, "id in steps page");

  // let nameObj = { titrationname, id };

  const handleStepname = async () => {
    const data = JSON.stringify({
      titId: location.state.tidId.id,
      // tidId: titrationid,
      // titrationName: location.state.tidId.titrationName,
      titrationName: titrationName,
      stepNum: Stepnumber,
    });
    const res = await axios.post(
      // "https://listool.digitivy.com/list-tool-runner/rest/titration/saveTitrationStep",
      SERVERURL + "saveTitrationStep",
      data,
      {
        headers: {
          // Overwrite Axios's automatically set Content-Type
          "Content-Type": "application/json",
          Authorization: `Basic ${token}`,
        },
      }
    );
    setNumberId(res.data.step_id);
    setTitIdname(res.data.stepName);
    setstepNum(res.data.step_num);
    sendid = res.data.step_id;
    sendname = res.data.stepName;
    stepId = res.data.step_id;

    stepConcat = res.data.stepName + res.data.step_id;
  };

  const notify = () => {
    toast.success(
      "successfully submitted data",
      { position: toast.POSITION.TOP_CENTER },
      { autoClose: 3000 }
    );
  };

  const onChangeFile = (e) => {
    generatePreSignedPutUrl(
      e.target.files[0].name,
      e.target.files[0].type,
      e.target.files[0]
    );
    setMatchData(e.target.files[0].name);
    if (e.target.name === "matchFile") {
      setValue("matchFile", e.target.files[0].name);
    }

    e.target.value = null;
  };
  // console.log(matchData, "Match File in edit Step");

  const generatePreSignedPutUrl = (fileName, fileType, file) => {
    myBucket.getSignedUrl(
      "putObject",
      {
        Key: fileName,
        ContentType: fileType,
        Expires: URL_EXPIRATION_TIME,
      },
      (err, url) => {
        fetch(url, {
          method: "PUT",
          body: file,
        }).then((res) => {
          // console.log(res, "resssssssssssss");
          // DO WHATEVER YOU WANT
        });
        return url;
      }
    );
  };
  // Edit step Dialogue box opens and displays the selected step details.
  const showEdit = (data1) => {
    setEdit(true);
    setValue("stepName", Stepname);
    setValue("sourceCol", sourceCol);
    setValue("matchCol", matchCol);
    setValue("matchFile", matchFile);
    setValue("sourceFile", data1 && data1.sourceFile);
    setValue("matchType", matchType);
    setValue("action", action);
    setValue("confidenceThreshold", data1 && data1.confidenceThreshold);
  };

  // Edit step Dialogue box closes
  const editClose = () => {
    setEdit(false);
    window.location.reload();
  };

  const config = {
    bucketName: S3_BUCKET,
    region: REGION,
    accessKeyId: ACCESS_KEY,
    secretAccessKey: SECRET_ACCESS_KEY,
  };

  // console.log(location, "location in steps page");
  const onSave = async (data1) => {
    const save = JSON.stringify({
      // titrationName: location.state.tidId.titrationName,
      titrationName: titrationName,
      stepName: Stepname,
      matchFile: matchData,
      sourceFile: sourcefile,
      output_file: outputFile,
      status: status,
      sourceCol: data1 && data1.sourceCol,
      matchCol: data1 && data1.matchCol,
      matchType: data1 && data1.matchType,
      titId: titId,
      // titId: titrationid,
      stepId: Step_id,
      stepNum: stepnum,
      totalCount: Totalcount,
      matchCount: Matchcount,
      suppressCount: Suppresscount,
      wildCardSearch: data1 && data1.wildCardSearch,
      action: data1 && data1.action,
      confidenceThreshold: data1 && data1.confidenceThreshold,
    });
    const res = await axios.post(
      // "https://listool.digitivy.com/list-tool-runner/rest/titration/editTitrationStep",
      SERVERURL + "editTitrationStep",
      save,
      {
        headers: {
          // Overwrite Axios's automatically set Content-Type
          "Content-Type": "application/json",
          Authorization: `Basic ${token}`,
        },
      }
    );
    // console.log(res);
    // console.log(res.data);
    idEdit = res.data;
    setEditid(res.data);
    onSubmit(data1);
    // loadFunction();
    setText(data1);
    setEdit(false);
  };
  // console.log(editid, "edit id");
  const onSubmit = async (data1) => {
    // console.log(editid, "edit id");
    // console.log(idEdit, "Variable id");
    const save = JSON.stringify({
      // titrationName: location.state.tidId.titrationName,
      titrationName: titrationName,
      stepName: Stepname,
      stepNum: stepnum,
      stepId: idEdit,
      matchFile: matchData,
      sourceFile: sourcefile,
      sourceCol: data1 && data1.sourceCol,
      matchCol: data1 && data1.matchCol,
      matchType: data1 && data1.matchType,
      wildCardSearch: data1 && data1.wildCardSearch,
      action: data1 && data1.action,
      confidenceThreshold: data1 && data1.confidenceThreshold,
    });

    const res = await axios.post(
      // "https://listool.digitivy.com/list-tool-runner/rest/titration/runStep",
      SERVERURL + "runStep",
      save,
      {
        headers: {
          // Overwrite Axios's automatically set Content-Type
          "Content-Type": "application/json",
          Authorization: `Basic ${token}`,
        },
      }
    );
    setChecked(false);
    loadFunction();
    var updatedUrl = res.data[0];
    setText(data1);
    setEdit(false);
  };

  const generatePresignedUrlDownloadFile = (item) => {
    myBucket.getSignedUrl(
      "getObject",
      {
        Key: item,
        Expires: URL_EXPIRATION_TIME,
      },
      (err, url) => {
        saveAs(url);
        return url;
      }
    );
  };

  const removeFunction = async () => {
    const removeObj = JSON.stringify({ stepId: Step_id, titId: id });

    const res = await axios.post(
      // "https://listool.digitivy.com/list-tool-runner/rest/titration/removeTitrationStep",
      SERVERURL + "removeTitrationStep",
      removeObj,
      {
        headers: {
          // Overwrite Axios's automatically set Content-Type
          "Content-Type": "application/json",
          Authorization: `Basic ${token}`,
        },
      }
    );
    // console.log(res, "response in remove Function");
    setChecked(false);
    loadFunction();
  };

  return (
    <Row>
      <Col xs={2} md={2} style={{ paddingRight: 0, paddingLeft: 0 }}>
        <Sidebar />
      </Col>
      <Col
        xs={10}
        md={10}
        style={{ paddingLeft: 0, backgroundColor: "#F9FBFD" }}
      >
        <Navbar />
        <h3 style={{ paddingLeft: 30, color: "#1D417A" }}>
          New Titration:{location.state.detail.titrationname}
        </h3>

        <Container>
          <Col sm={8} md={8} lg={12}></Col>
          <Col sm={12} md={12} lg={12}>
            <div className="boxform">
              <div style={{ textAlign: "right", padding: 16 }}>
                <button className="btn1" onClick={handleShow}>
                  Add Step
                </button>
                <button className="btn1" onClick={showEdit}>
                  Edit Step
                </button>
                {/* <button className="btn1" onClick={remove}> */}
                <button className="btn1" onClick={removeFunction}>
                  Remove Step
                </button>
              </div>
              <div>
                <p style={{ paddingLeft: 20, fontSize: 20 }}>Titration Steps</p>
              </div>
              {showModal && (
                <NewTitrationmodal
                  // data={handleData}
                  onClose={handleSampleClose}
                  stepnumber={handleStepNumber}
                  titration={titrationName}
                  source={sourcefile}
                  number={titIdname + numberId}
                  name={titIdname}
                  stepNum={stepnum}
                  stepid={numberId}
                />
              )}
              <div>
                <Modal show={edit} onHide={editClose}>
                  <Modal.Header closeButton>
                    <Modal.Title>Edit Titration Step</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <form className="data">
                      <div>
                        <label htmlFor="matchFile">Match File</label>
                        <input
                          type="text"
                          {...register("matchFile", { required: true })}
                          autoComplete="off"
                        />
                        {errors.matchFile && (
                          <p style={{ color: "red", fontSize: 12 }}>
                            File need to be selected
                          </p>
                        )}

                        <label className="custom-file-upload">
                          <input
                            type="file"
                            name="matchFile"
                            accept=".csv"
                            placeholder="Match File"
                            style={{ display: "none" }}
                            onChange={(e) => {
                              onChangeFile(e);
                            }}
                            autoComplete="off"
                          />
                          Choose
                        </label>
                      </div>
                      <div>
                        <label htmlFor="sourceCol">Source Col</label>
                        <input
                          type="text"
                          {...register("sourceCol", { required: true })}
                          autoComplete="off"
                        />
                      </div>
                      <div>
                        <label htmlFor="matchCol">Match Col</label>
                        <input
                          type="text"
                          {...register("matchCol", { required: true })}
                          autoComplete="off"
                        />
                      </div>
                      <div>
                        <label htmlFor="matchType">Match Type</label>
                        <select
                          name="matchType"
                          id="matchType"
                          {...register("matchType", { required: true })}
                          // autoComplete="off"
                        >
                          <option value=""></option>
                          <option value="emailAddress">Email Address</option>
                          <option value="companyName">Company Name</option>
                          <option value="emailDomain">Email Domain</option>
                          <option value="name">Name</option>
                          <option value="zip">Zip</option>
                          <option value="state">State</option>
                          <option value="title">Title</option>
                          <option value="wildCardSearch">Wild Card</option>
                          <option value="phoneNumber">Phone Number</option>
                        </select>
                      </div>
                      <div>
                        <label htmlFor="action">Action</label>
                        <select
                          name="action"
                          id="action"
                          {...register("action", { required: true })}
                        >
                          <option value=""></option>
                          <option value="match">match</option>
                          <option value="remove">suppress</option>
                        </select>
                      </div>
                      <div>
                        <label htmlFor="confidenceThreshold">Confidence</label>
                        <input
                          type="number"
                          step="0.1"
                          {...register("confidenceThreshold", {
                            required: true,
                          })}
                        />
                      </div>
                      <div>
                        <button
                          className="formbtn"
                          onClick={handleSubmit(onSave)}
                        >
                          Save
                        </button>
                      </div>
                    </form>
                  </Modal.Body>
                </Modal>
              </div>
              <Table striped bordered hover>
                <thead>
                  <tr className="Table-headers">
                    <th>Input File</th>
                    <th>Action</th>
                    <th>Total Records</th>
                    <th>Match Count</th>
                    <th>Suppresion Count</th>
                    <th>Output File</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {results &&
                    results.length > 0 &&
                    results.map((obj, index) => (
                      <tr key={index}>
                        {/* {obj.steps &&
                          obj.steps.length > 0 &&
                          obj.steps.map((Name, index) => (
                            <td key={index}>{Name.step_id}</td>
                          ))} */}
                        {obj.steps &&
                          obj.steps.length > 0 &&
                          obj.steps.map((Name, index) => (
                            <td key={index}>
                              {Name.sourceFile}
                              <FaCloudDownloadAlt
                                style={{
                                  color: "green",
                                  height: 25,
                                  width: 25,
                                }}
                                onClick={() =>
                                  generatePresignedUrlDownloadFile(
                                    Name.Name.sourceFile
                                  )
                                }
                              />
                            </td>
                          ))}
                        {obj.steps &&
                          obj.steps.length > 0 &&
                          obj.steps.map((Name, index) => (
                            <td key={index}>{Name.action}</td>
                          ))}
                        {obj.steps &&
                          obj.steps.length > 0 &&
                          obj.steps.map((Name, index) => (
                            <td key={index}>{Name.totalCount}</td>
                          ))}
                        {obj.steps &&
                          obj.steps.length > 0 &&
                          obj.steps.map((Name, index) => (
                            <td key={index}>{Name.matchCount}</td>
                          ))}
                        {obj.steps &&
                          obj.steps.length > 0 &&
                          obj.steps.map((Name, index) => (
                            <td key={index}>{Name.suppressCount}</td>
                          ))}
                        {obj.steps &&
                          obj.steps.length > 0 &&
                          obj.steps.map((Name, index) => (
                            <td key={index}>
                              {Name.outputFilePath}
                              <FaCloudDownloadAlt
                                style={{
                                  color: "green",
                                  height: 25,
                                  width: 25,
                                }}
                                onClick={() =>
                                  generatePresignedUrlDownloadFile(
                                    Name.outputFilePath
                                  )
                                }
                              />
                            </td>
                          ))}
                        {obj.steps &&
                          obj.steps.length > 0 &&
                          obj.steps.map((Name, index) => (
                            <td key={index}>{Name.status}</td>
                          ))}
                        {obj.steps &&
                          obj.steps.length > 0 &&
                          obj.steps.map((Name, index) => (
                            <td key={index}>
                              <input
                                type="checkbox"
                                value={checked}
                                onClick={() => {
                                  setChecked(!checked);
                                  if (checked === true) {
                                    setTitId(obj.id);
                                    setStep_Id(Name.step_id);
                                    setTotalCount(Name.totalCount);
                                    setMatchCount(Name.matchCount);
                                    setOutputFile(Name.outputFilePath);
                                    setStatus(Name.status);
                                    setSuppressCount(Name.suppressCount);
                                    setStepName(Name.stepName);
                                    setSourceCol(Name.sourceCol);
                                    setMatchCol(Name.matchCol);
                                    setMatchFile(Name.matchFile);
                                    setMatchType(Name.matchType);
                                    setAction(Name.action);
                                    setConfidence(Name.confidenceThreshold);
                                    setSource(Name.sourceFile);
                                  } else {
                                    setChecked(false);
                                  }
                                }}
                              />
                            </td>
                          ))}
                      </tr>
                    ))}
                </tbody>
              </Table>
              <div style={{ textAlign: "right", padding: 20 }}>
                {redirectpath && redirectpath !== "" ? (
                  <Redirect to={redirectpath} />
                ) : null}
                <button className="close" onClick={closeComponent}>
                  Close
                </button>
              </div>
            </div>
          </Col>
        </Container>
      </Col>
    </Row>
  );
}

export default Steps;
