import React, { useState, useEffect } from "react";
import Sidebar from "./Sidebar";
import "bootstrap/dist/css/bootstrap.min.css";
import { Row, Col, Container } from "react-bootstrap";
import Navbar from "./Navbar";
import Api from "../middleware";
import AWS from "aws-sdk";
import "../styles/style.css";
import { useForm } from "react-hook-form";
import { Modal } from "react-bootstrap";
import { SERVERURL } from "../configuration/config_url";
import { Redirect, useHistory, withRouter, Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { uploadFile } from "react-s3";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

let loadSteps = [];
let userDetails = {};
let fetchuserDetails = {};
let userEmail = "";
let userProfile = "";
const response = "";
let name = "";
let role = "";

AWS.config.update({
  accessKeyId: "AKIAXTPFNQTGXF3CI35R",
  secretAccessKey: "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",
});
const S3_BUCKET = "solpub-list-data";
const REGION = "us-east-1";
const URL_EXPIRATION_TIME = 900; // in seconds
const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});

const Main = (props) => {
  // const search = useLocation().search;
  // let steps_id = new URLSearchParams(search).get("steps_id");
  // let campaign_id = new URLSearchParams(search).get("campaign_id");
  // let profile = new URLSearchParams(search).get("profile");
  // let email = new URLSearchParams(search).get("email");
  // let user_id = new URLSearchParams(search).get("user_id");
  const [items, setItems] = useState([]);

  // localStorage.setItem("steps_id", steps_id);
  // localStorage.setItem("campaign_id", steps_id);
  // localStorage.setItem("profile", steps_id);
  // // localStorage.setItem("email", email);
  // userDetails = {
  //   steps_id: steps_id,
  //   campaign_id: campaign_id,
  //   profile: profile,
  //   email: email,
  //   user_id: user_id,
  // };
  // localStorage.setItem("userDetails", JSON.stringify(userDetails));
  // userEmail = JSON.parse(localStorage.getItem("userDetails".email));
  // userProfile = JSON.parse(localStorage.getItem("userDetails".profile));
  useEffect(() => {
    // fetchuserDetails = JSON.parse(localStorage.getItem("userDetails"));
    const items = JSON.parse(localStorage.getItem("userDetails"));
    if (items) {
      setItems(items);
    }
    // fetchuserDetails = {
    //   campaign_id: retrievedobj && retrievedobj.campaign_id,
    //   profile: retrievedobj && retrievedobj.profile,
    //   email: retrievedobj && retrievedobj.email,
    //   user_id: retrievedobj && retrievedobj.user_id,
    // };
    // userEmail = JSON.parse(localStorage.getItem("userDetails".email));
    // userProfile = JSON.parse(localStorage.getItem("userDetails".profile));
    // userEmail = fetchuserDetails.email;
    // userProfile = fetchuserDetails.profile;
  }, []);

  // for (let key in userDetails) {
  //   // console.log(key + ":", userDetails[key]);
  // }
  // const abc = JSON.parse(JSON.stringify(userDetails));

  // name = email;
  // role = profile;
  // console.log(steps_id, campaign_id, "Steps_id & Campaign_id");
  let history = useHistory();
  const username = "admin";
  const password = "f3c6dfbe50cff2d05dce3180e3569910";
  const token = Buffer.from(`${username}:${password}`, "utf8").toString(
    "base64"
  );

  const {
    register,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const location = useLocation();
  const [formBox, setFormBox] = useState(false);
  const [loadData, setLoadData] = useState([]);
  const [listItem, setListItem] = useState("");
  const [detail, setDetail] = useState(false);
  const [list, setList] = useState([]);
  const [error, setError] = useState();
  const [netError, setNetError] = useState(false);
  const [sourceData, setSourceData] = useState(null);
  const [loading, setLoading] = useState(false);

  const detailClose = () => setDetail(false);
  const handleShow = () => setDetail(true);

  const hideComponent = () => {
    setDetail(true);
    setFormBox(true);
  };

  const handleTitration = (data) => {
    setFormBox(false);
  };

  const notify = () => {
    toast.success(
      "Loading Data",
      { position: toast.POSITION.TOP_CENTER },
      { autoClose: 3000 }
    );
  };

  const onChangeFile = (e) => {
    generatePreSignedPutUrl(
      e.target.files[0].name,
      e.target.files[0].type,
      e.target.files[0]
    );
    if (e.target.name === "sourceFile") {
      setValue("sourceFile", e.target.files[0].name);
      setSourceData(e.target.files[0]);
    }
  };

  const generatePreSignedPutUrl = (fileName, fileType, file) => {
    myBucket.getSignedUrl(
      "putObject",
      {
        Key: fileName,
        ContentType: fileType,
        Expires: URL_EXPIRATION_TIME,
      },
      (err, url) => {
        fetch(url, {
          method: "PUT",
          body: file,
        }).then((res) => {
          // console.log(res, "resssssssssssss");
          // DO WHATEVER YOU WANT
        });
        return url;
      }
    );
  };

  const onSubmit = async (data) => {
    const sfile = await generatePreSignedPutUrl(sourceData);
    const name = JSON.stringify({
      titrationName: data.titrationname,
    });
    //   const res = await axios.post(
    //     "https://listool.digitivy.com/list-tool-runner/rest/titration/saveTitration",
    //     name,
    //     {
    //       headers: {
    //         // Overwrite Axios's automatically set Content-Type
    //         "Content-Type": "application/json",
    //         Authorization: `Basic ${token}`,
    //       },
    //     }
    //   );
    //   // if (res.data === null) {
    //   //   notifyError();
    //   // } else {
    //   //   notifyInfo();
    //   // }
    //   // console.log(res.data, "data in titlepage");
    //   // console.log(sourceData, "Sourcedata in main page");
    //   history.push({
    //     pathname: "/addstep",
    //     // pathname: "/addstep",
    //     state: { detail: data, tidId: res.data, sourceFile: sourceData },
    //   });
    //   setLoading(true);
    // };

    const res = await axios
      .post(
        // "https://listool.digitivy.com/list-tool-runner/rest/titration/saveTitration",
        SERVERURL + "saveTitration",
        name,
        {
          headers: {
            // Overwrite Axios's automatically set Content-Type
            "Content-Type": "application/json",
            Authorization: `Basic ${token}`,
          },
        }
      )
      .then((response) => {
        console.log(response, "response");
        if (response.data.statusCode == 200) {
          // history.push;
          console.log(response, "response");
          history.push({
            pathname: "/addstep",
            // pathname: "/addstep",
            state: {
              detail: data,
              tidId: response.data,
              sourceFile: sourceData,
            },
          });
          toast.success(
            "Successfully Fetched the response",
            { position: toast.POSITION.TOP_CENTER },
            { autoClose: 3000 }
          );
        } else {
          toast.error(
            response.data.Response,
            { position: toast.POSITION.TOP_CENTER },
            { autoClose: 3000 }
          );
        }
      })
      .catch((error) => {
        toast.error(
          "Something went wrong server is not responding",
          { position: toast.POSITION.TOP_CENTER },
          { autoClose: 3000 }
        );
        console.error("Something went wrong!", error);
      });
    // setLoading(false);
  };

  // const notifyInfo = () => {
  //   toast.info(
  //     "connection established",
  //     { position: toast.POSITION.TOP_CENTER },
  //     { autoClose: 3000 }
  //   );
  // };

  // const notifyError = () => {
  //   toast.error(
  //     "The Server is not Responding",
  //     { position: toast.POSITION.TOP_CENTER },
  //     { autoClose: 5000 }
  //     // { autoClose: "false" }
  //   );
  // };

  return (
    <>
      <Row style={{ width: "100%", margin: 0 }}>
        <Col xs={2} md={2} style={{ paddingRight: 0, paddingLeft: 0 }}>
          <Sidebar namedetail={name} emaildetail={role} />
        </Col>
        <Col
          xs={10}
          md={10}
          style={{ paddingLeft: 0, backgroundColor: "#F9FBFD" }}
        >
          <Navbar />
          <h3 style={{ paddingLeft: 30, color: "#1D417A" }}>Titrations</h3>
          {!items.email || !items.profile ? (
            <div>
              <p> No Login Credentials Please login from Arcman Website</p>
            </div>
          ) : (
            <Container>
              <Col sm={8} md={8} lg={12}>
                <div style={{ textAlign: "right", padding: "20 10" }}></div>
              </Col>
              <Col sm={12} md={12} lg={12}>
                {/* <div>
                <Link
                  to={{
                    pathname: "https://arcmanuat.solpub.com/",
                  }}
                  target="_blank"
                >
                  Click Here
                </Link>
              </div> */}

                <div className="ntbox">
                  <h3
                    style={{
                      color: "#1D417A",
                      marginBottom: 20,
                    }}
                  >
                    New Titration
                  </h3>
                  <form className="data">
                    <label htmlFor="titrationName">Name</label>
                    <input
                      style={{ marginLeft: 10, marginBottom: 10 }}
                      {...register("titrationname", { required: true })}
                    />
                    {errors.titrationName && (
                      <p style={{ color: "red", fontSize: 12 }}>
                        Name cannot be empty.
                      </p>
                    )}
                    <br />
                    <label htmlFor="sourceFile">Source File</label>
                    <input
                      type="text"
                      style={{ marginLeft: 10 }}
                      {...register("sourceFile", { required: true })}
                      autoComplete="off"
                    />
                    {errors.sourceFile && (
                      <p style={{ color: "red", fontSize: 12 }}>
                        File need to be selected
                      </p>
                    )}
                    <label className="custom-file-upload">
                      <input
                        type="file"
                        accept=".csv"
                        name="sourceFile"
                        style={{ display: "none" }}
                        onChange={(e) => {
                          onChangeFile(e);
                        }}
                        // autoComplete="off"
                      />
                      {errors.sourceFile?.type === "csv" && (
                        <p style={{ color: "red", fontSize: 12 }}>
                          csv files are required
                        </p>
                      )}
                      Choose
                    </label>
                    <br />
                    <div style={{ textAlign: "center", marginTop: 20 }}>
                      <button
                        className="next"
                        type="submit"
                        disabled={loading}
                        onClick={handleSubmit(onSubmit)}
                      >
                        {loading && <i className="fa fa-refresh fa-spin"></i>}
                        Next
                      </button>
                    </div>
                  </form>
                </div>
              </Col>
            </Container>
          )}
        </Col>
      </Row>
    </>
  );
};

export default withRouter(Main);
