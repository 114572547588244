import React from "react";
import Routes from "./components/Routes";
import { BrowserRouter as Router } from "react-router-dom";
import "./styles/style.css";
import AppLogout from "../src/AppLogout";

function App() {
  return (
    <>
      <Router>
        <AppLogout>
          <Routes />
        </AppLogout>
      </Router>
    </>
  );
}
export default App;
