import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Main from "./Main";
import Steps from "./Steps";

import Dashboard from "./Dashboard";
import Titlepage from "./titlepage";

function Routes() {
  return (
    <div>
      <Router>
        <Switch>
          <Route exact path="/titrations" component={Main}>
            <Main />
          </Route>
          <Route exact path="/" component={Titlepage}>
            <Titlepage />
          </Route>
          <Route exact path="/addstep" component={Steps}>
            <Steps />
          </Route>
          <Route exact path="/list" component={Dashboard}>
            <Dashboard />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default Routes;
