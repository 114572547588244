import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import "../styles/style.css";
import Api from "../middleware";
import { uploadFile } from "react-s3";
import AWS from "aws-sdk";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { saveAs } from "file-saver";
import Steps from "./Steps";
import axios from "axios";
import { SERVERURL } from "../configuration/config_url.js";
import { red } from "@mui/material/colors";
toast.configure();

let downloadFile = "";
var path = "";
let sourceFile = "";
let titrationName = "";
let stepNumber = "";
let dataObject = {};
let message = "";

AWS.config.update({
  accessKeyId: "AKIAXTPFNQTGXF3CI35R",
  secretAccessKey: "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",
});

const S3_BUCKET = "solpub-list-data";
const REGION = "us-east-1";
const URL_EXPIRATION_TIME = 900; // in seconds
const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});

function NewTitrationmodal(props) {
  const [showModal, setShow] = useState(true);
  const [response, setResponse] = useState([]);
  const [errorMessage, setErrorMessage] = useState();

  // const config = {
  //   bucketName: S3_BUCKET,
  //   region: REGION,
  //   accessKeyId: ACCESS_KEY,
  //   secretAccessKey: SECRET_ACCESS_KEY,
  // };

  const [sourceData, setSourceData] = useState(null);
  const [matchData, setMatchData] = useState(null);
  const username = "admin";
  const password = "f3c6dfbe50cff2d05dce3180e3569910";
  const token = Buffer.from(`${username}:${password}`, "utf8").toString(
    "base64"
  );

  const {
    register,
    formState: { errors },
    setValue,
    handleSubmit,
  } = useForm();

  const [data, setData] = useState({
    // fileName: "",
    titrationName: "",
    sourceFile: "",
    matchFile: "",
    sourceCol: "",
    action: "",
    confidence: "",
    matchType: "",
  });

  console.log(props, "New Titrationmodal");

  const submitTitration = async (data) => {
    const check = JSON.stringify({
      titrationName: props && props.titration,
      stepNum: props && props.stepNum,
      stepName: props && props.name,
      sourceFile: props && props.source,
      stepId: props && props.stepid,
      matchFile: matchData.name,
      sourceCol: data.sourceCol,
      matchCol: data.matchCol,
      matchType: data.matchType,
      wildCardSearch: data.wildCardSearch,
      action: data.action,
      confidenceThreshold: data.confidenceThreshold,
    });
    // const res = await axios.post(
    //   "https://listool.digitivy.com/list-tool-runner/rest/titration/runStep",
    //   check,
    //   {
    //     headers: {
    //       // Overwrite Axios's automatically set Content-Type
    //       "Content-Type": "application/json",
    //       Authorization: `Basic ${token}`,
    //     },
    //   }
    // );
    // console.log(res.data);
    // if (res.data[6] !== 0 || res.data !== null) {
    //   stepNumber = res.data[6];
    //   console.log(stepNumber, "Step Number");
    //   handleNumber(stepNumber);
    // }
    // if (res.data !== null) {
    //   if (res.data.errorMessage !== null) {
    //     message = res.data.errorMessage;
    //     notifyError();
    //     props.onClose();
    //   } else {
    //     props.onClose();
    //     setResponse(res.data.steps);
    //     // dataObject = {
    //     //   titrationName: props && props.titration,
    //     //   stepName: data.stepName,
    //     //   sourceFile: props && props.source,
    //     //   matchFile: matchData.name,
    //     //   sourceCol: data.sourceCol,
    //     //   matchCol: data.matchCol,
    //     //   matchType: data.matchType,
    //     //   // matchZipWildCard: data.matchZipWildCard,
    //     //   wildCardSearch: data.wildCardSearch,
    //     //   action: data.action,
    //     //   confidenceThreshold: data.confidenceThreshold,
    //     // };
    //     // var updatedUrl = res.data[0];
    //     // if (res.data[0] != undefined) {
    //     //   updatedUrl = updatedUrl.slice(42, -4);
    //     //   updatedUrl =
    //     //     "https://solpub-list-data.s3.amazonaws.com/" + updatedUrl + ".csv";
    //     //   dataObject["url"] = updatedUrl;
    //     // }
    //     // path = dataObject;
    //   }
    // }
    const res = await axios
      .post(
        // "https://listool.digitivy.com/list-tool-runner/rest/titration/runStep",
        SERVERURL + "runStep",
        check,
        {
          headers: {
            // Overwrite Axios's automatically set Content-Type
            "Content-Type": "application/json",
            Authorization: `Basic ${token}`,
          },
        }
      )
      .then((response) => {
        // console.log(response, "response in New");
        console.log(response.data, "Data in response");
        if (response.data[4] === "200") {
          // history.push;
          // console.log(response, "response in the try block");
          console.log(response[4], "Status");
          toast.info(
            "Successfully submitted the data",
            { position: toast.POSITION.TOP_CENTER },
            { autoClose: 3000 }
          );
          props.onClose();
        } else {
          toast.error(
            response.data.errorMessage,
            { position: toast.POSITION.TOP_CENTER },
            { autoClose: 3000 }
          );
          props.onClose();
        }
      })
      .catch((error) => {
        toast.error(
          "Something went wrong server is not responding",
          { position: toast.POSITION.TOP_CENTER },
          { autoClose: 3000 }
        );
        console.error("Something went wrong!", error);
        props.onClose();
      });
  };

  const handleNumber = (stepNumber) => {
    props.stepnumber(stepNumber);
  };

  const handleClose = () => {
    // props.data(path);
    props.onClose();
  };

  const notify = () => {
    toast.info(
      "connection established",
      { position: toast.POSITION.TOP_CENTER },
      { autoClose: 3000 }
    );
  };

  const notifyError = () => {
    toast.error(
      message,
      { position: toast.POSITION.TOP_CENTER },
      { autoClose: 5000 }
      // { autoClose: "false" }
    );
  };

  const onChangeFile = (e) => {
    // aws configuration.

    // react hook form.
    generatePreSignedPutUrl(
      e.target.files[0].name,
      e.target.files[0].type,
      e.target.files[0]
    );
    if (e.target.name === "matchFile") {
      setValue("matchFile", e.target.files[0].name);
      setMatchData(e.target.files[0]);
    }

    e.target.value = null;
  };

  const generatePreSignedPutUrl = (fileName, fileType, file) => {
    myBucket.getSignedUrl(
      "putObject",
      {
        Key: fileName,
        ContentType: fileType,
        Expires: URL_EXPIRATION_TIME,
      },
      (err, url) => {
        fetch(url, {
          method: "PUT",
          body: file,
        }).then((res) => {
          console.log(res, "resssssssssssss");
          // DO WHATEVER YOU WANT
        });
        return url;
      }
    );
  };

  const saveFile = () => {
    saveAs(downloadFile);
    // props.OnClose();
  };

  if (data) {
    return (
      <>
        <Modal
          show={showModal}
          onHide={handleClose}
          // centered size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          size="lg"
        >
          <div>
            <Modal.Header closeButton style={{ border: "none" }}>
              <h4 style={{ fontSize: 22 }}>New Titration Step</h4>
            </Modal.Header>
            <Modal.Body>
              <form className="data">
                <div>
                  <label
                    htmlFor="matchFile"
                    style={{
                      color: "black",
                      // fontWeight: 100
                    }}
                  >
                    Match File
                  </label>
                  <input
                    type="text"
                    {...register("matchFile", { required: true })}
                    autoComplete="off"
                  />
                  {errors.matchFile && (
                    <p style={{ color: "red", fontSize: 12 }}>
                      File need to be selected
                    </p>
                  )}
                  <label className="custom-file-upload">
                    <input
                      type="file"
                      accept=".csv"
                      name="matchFile"
                      placeholder="Match File"
                      style={{ display: "none" }}
                      onChange={(e) => {
                        onChangeFile(e);
                      }}
                      autoComplete="off"
                    />
                    Choose
                    {errors.matchFile?.type === "csv" && (
                      <p style={{ color: "red", fontSize: 12 }}>
                        csv files are required
                      </p>
                    )}
                  </label>
                </div>
                <br />

                <div>
                  <label
                    htmlFor="sourceCol"
                    style={{
                      color: "black",
                      // fontWeight: 100
                    }}
                  >
                    Source Col
                  </label>
                  <input
                    type="text"
                    {...register("sourceCol", { required: true })}
                    autoComplete="off"
                  />
                  {errors.sourceCol && (
                    <p style={{ color: "red", fontSize: 12 }}>
                      Source Col cannot be empty.
                    </p>
                  )}
                </div>
                <br />

                <div>
                  <label
                    htmlFor="matchCol"
                    style={{
                      color: "black",
                      // fontWeight: 100
                    }}
                  >
                    Match Col
                  </label>
                  <input
                    type="text"
                    {...register("matchCol", { required: true })}
                    autoComplete="off"
                  />
                  {errors.matchCol && (
                    <p style={{ color: "red", fontSize: 12 }}>
                      Match Col cannot be empty.
                    </p>
                  )}
                </div>
                <br />
                <div>
                  <label
                    htmlFor="matchType"
                    style={{
                      color: "black",
                      // fontWeight: 100
                    }}
                  >
                    Match Type
                  </label>
                  <select
                    name="matchType"
                    id="matchType"
                    {...register("matchType", { required: true })}
                    autoComplete="off"
                  >
                    <option value=""></option>
                    <option value="emailAddress">Email Address</option>
                    <option value="companyName">Company Name</option>
                    <option value="emailDomain">Email Domain</option>
                    <option value="name">Name</option>
                    <option value="zip">Zip</option>
                    <option value="state">State</option>
                    <option value="title">Title</option>
                    <option value="wildCardSearch">Wild Card</option>
                    <option value="phoneNumber">Phone Number</option>
                  </select>
                  {errors.matchType && (
                    <p style={{ color: "red", fontSize: 12 }}>
                      Match Type cannot be empty.
                    </p>
                  )}
                </div>
                <br />
                <br />
                <div>
                  <label
                    htmlFor="action"
                    style={{
                      color: "black",
                      // fontWeight: 100
                    }}
                  >
                    Action
                  </label>
                  <select
                    name="action"
                    id="action"
                    {...register("action", { required: true })}
                  >
                    <option value=""></option>
                    <option value="match">match</option>
                    <option value="remove">suppress</option>
                  </select>
                  {errors.sourceCol && (
                    <p style={{ color: "red", fontSize: 12 }}>
                      Action cannot be empty.
                    </p>
                  )}
                </div>
                <br />
                <div>
                  <label
                    htmlFor="confidenceThreshold"
                    style={{
                      color: "black",
                      // fontWeight: 100
                    }}
                  >
                    Confidence
                  </label>
                  <input
                    type="number"
                    step="0.1"
                    {...register("confidenceThreshold", { required: true })}
                    style={{ width: 103 }}
                    autoComplete="off"
                  />
                  {errors.sourceCol && (
                    <p style={{ color: "red", fontSize: 12 }}>
                      Confidence cannot be empty.
                    </p>
                  )}
                </div>
                <br />

                <br />
                <div
                  style={{
                    textAlign: "right",
                    color: "#1D417A",
                    paddingTop: 10,
                  }}
                >
                  <button
                    className="formbtn"
                    onClick={handleSubmit(submitTitration)}
                  >
                    Save
                  </button>
                </div>
              </form>
            </Modal.Body>
          </div>
        </Modal>
      </>
    );
  }
  return null;
}

export default NewTitrationmodal;
