import React, { useState, useEffect } from "react";
import logo from "../images/Mask_Group_32.png";
import { FaRegBell } from "react-icons/fa";
let username = "";
let designation = "";
function Navigation(props) {
  // let username = props && props.data;
  // let designation = props && props.role;
  // console.log(username, "Name through Props in Navbar");
  // console.log(designation, "Designation through props in Navbar");
  // console.log(props);
  const [userName, setUserName] = useState();
  const [profile, setProfile] = useState();
  const [items, setItems] = useState([]);
  let storageobj = {};
  // let obj = {};
  useEffect(() => {
    // console.log(
    //   "Local Storage",
    //   JSON.parse(localStorage.getItem("userDetails"))
    // );
    // retrievedobj = JSON.parse(localStorage.getItem("userDetails"));
    const items = JSON.parse(localStorage.getItem("userDetails"));
    if (items) {
      setItems(items);
    }
  }, []);
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-end",
        marginTop: "15px",
        marginRight: "10px",
        alignItems: "center",
      }}
    >
      {/* <FaRegBell style={{ marginRight: 30 }} /> */}
      <div>
        <div style={{ display: "flex", alignContent: "center", padding: 20 }}>
          {/* <img
            src={logo}
            alt="logo"
            style={{
              marginRight: 10,
              borderRadius: "50%",
              width: 50,
              height: 50,
            }}
          /> */}
          <div>
            <p style={{ margin: 0, padding: 0, fontSize: 16 }}>{items.email}</p>
            <p style={{ margin: 0, padding: 0, fontSize: 10 }}>
              {items.profile}
            </p>
            {/* <p>{username && designation}</p> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Navigation;
